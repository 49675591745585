import React, { Component } from 'react';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import { navigate } from "gatsby";
import {jwtVerifyAndDecode} from '../../helper/verifyToken';
import infeedo_logo from '../../../static/images/infeedo.svg';
import handleException from '../../helper/errorHandling';


export default class DatabaseUploadMTMSecure extends Component {

    state = {
        uploading: false,
        use_employee_code: true,
        downloading: false,
        touch_points: [
            {
                title: "Manager Change",
                touch_point_key: "MANAGER_CHANGE",

            },
            {
                title: "Role Change",
                touch_point_key: "ROLE_CHANGE",

            },
            {
                title: "Project Change",
                touch_point_key: "PROJECT_CHANGE",

            },
            {
                title: "Location Change",
                touch_point_key: "LOCATION_CHANGE",

            },
            {
                title: "Appraisal",
                touch_point_key: "APPRAISAL",

            }
        ],
        selected_touch_point_key: 'MANAGER_CHANGE'
    } 

    componentDidMount(){
        jwtVerifyAndDecode((sessionStorage.token)).then((_decoded_token) =>{

        })
        .catch(() => { 
             navigate("/database-upload-mtm")
        })
    }

    downloadSampleFile = async () => {
        const { use_employee_code, downloading, selected_touch_point_key } = this.state;
        if(downloading){
            return;
        }
        this.setState({downloading: true});
        try {
            const response = await axios.get(`/v1/breadboard/data_sync/sample_file?use_employee_code=${use_employee_code ? 1 : 0}&touchpoint=${selected_touch_point_key}`, 
            {
                responseType: 'arraybuffer',
            });
            if(response && response.status === 201 && response.data && response.data.sso_url){ 
                window.location.href = response.data.sso_url;
                return;
            }
            const file_name = 'sample_mtm_file';
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${file_name}.csv`); 
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            handleException(error,'Download Failed');
        }
        this.setState({downloading: false});
    }

    openFileSelector = () => {
        const { uploading } = this.state;
        if(uploading){
            return;
        }
        document.getElementById('uploadUsers').click()
    }

    uploadCSV = async (e) => {
        if(e.target.files){
            const { use_employee_code, selected_touch_point_key} = this.state;
            const formData = new FormData();
            formData.append('csv', e.target.files[0]);
            formData.append('selected_touch_point' , selected_touch_point_key)
            this.setState({uploading: true});
            try {
                const response = await axios.post(`/v1/breadboard/data_sync/upload?use_employee_code=${use_employee_code ? 1 : 0}`, formData, { headers: {'Content-Type': 'multipart/form-data' }});
                if(response && response.status === 200){
                    NotificationManager.success(`${response.data ? response.data : 'File uploaded successfully!'}`);
                    this.closeModal();
                }
        
                if(response && response.status === 201 && response.data && response.data.sso_url){ 
                    window.location.href = response.data.sso_url;
                }
            } catch (error) {
                if(error.response){
                    if(error.response.status === 401){
                        NotificationManager.error("Link Expired");
                        this.goToHome();
                    } else if(error.response.status === 400 && error.response.data && error.response.data.data){
                        NotificationManager.error('Failed to upload file!');
                        const url = window.URL.createObjectURL(new Blob([error.response.data.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `error_file.csv`); 
                        document.body.appendChild(link);
                        link.click();
                    }
                    else {
                        handleException(error,'Upload failed');
                    }
                }
            }
            this.setState({uploading: false});
        }
    }

    selectIdentifier = (e) => {
        this.setState({use_employee_code: JSON.parse(e.target.value)})
    }

    goToHome = () => {
        sessionStorage.clear();
        setTimeout(() => { navigate("/") }, 2000);
    }

    selectTouchPoint = (selected_touch_point_key) => {
        this.setState({
            selected_touch_point_key
        });
    }

    render() {
        const { uploading, use_employee_code, downloading, touch_points, selected_touch_point_key } = this.state;
        return (
            <div className="column is-8 mar-auto pd-25 upload-section">

                <figure className="app-logo">
                    <img src={infeedo_logo} width="130px" alt="amber logo"/>
                </figure>
                <div className="columns is-mobile">
                    <div className="column no-pd-bottom"><h1 className="login-heading no-margin-padding-bottom font-montserrat">Database Upload for Moments that Matter</h1></div>
                </div>
                <p className="font-14">Using this feature you trigger moments for your organisation by uploading the users who have undergone a moment change.</p>
                <br/>
                <article className="message is-link">
                    <div className="message-body has-text-grey-darker">
                        <h2 className="has-text-weight-bold mg-bottom-10"><u>Guidelines to Upload File</u></h2>
                        <div className="content">
                            <ul className="line-height-li-22">
                                <li><b>Position your sheet’s columns as formatted in the sample file.</b> The system will not accept files with altered column position.</li>
                                <li><b>Do not remove any columns from the file, regardless of if they’re not pertinent to your organization.</b></li>
                                <li><b>Date format</b> must be <code>DD-MMM-YYYY</code> e.g. <b>04-JUL-2020</b>.</li>
                                <li><b>Boolean format</b> for upload is <code>TRUE/FALSE</code></li>
                                <li>Ensure there are <b>no duplicate email ID and employee code.</b></li>
                                <li>Upload maximum <b>2000 users</b> at a time.</li>

                            </ul>
                        </div>
                    </div>
                </article>
                <hr/>
                <div>
                    <h4 className="has-text-weight-bold"><span className="tag is-link">Step 1</span> &nbsp;&nbsp;<u>Select Unique Identifier</u></h4>
                    <br/>
                    <div className="control has-text-centered has-text-link">
                        <label className="radio">
                            <input type="radio" onChange={this.selectIdentifier} name="employee_code" value={true} checked={use_employee_code}/>
                            &nbsp;<b>Employee Code</b>
                        </label>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="radio">
                            <input type="radio" onChange={this.selectIdentifier} name="email" value={false} checked={!use_employee_code}/>
                            &nbsp;<b>Email ID</b>
                        </label>
                    </div>
                </div>
                <hr/>
                <div>
                    <h4 className="has-text-weight-bold"><span className="tag is-link">Step 2</span> &nbsp;&nbsp;<u>Download & Upload CSV</u></h4>
                    <br/>
                    <div className="control has-text-centered has-text-link">
                        {
                            touch_points.map(touch_point => (
                                <>
                                <label className="radio radio-mtm">
                                    <input type="radio" onChange={this.selectTouchPoint.bind(this, touch_point.touch_point_key)} name={touch_point.touch_point_key} value={true} checked={selected_touch_point_key === touch_point.touch_point_key}/>
                                    &nbsp;<b>{touch_point.title}</b>
                                </label>
                                </>
                            ))
                        }
                    </div>
                    <div className="columns flex-wrap has-text-centered pd-10">
                        <div className="column is-paddingless">
                            {downloading ?  
                            <div className="db-button" onClick={this.downloadSampleFile}>
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Downloading...
                                </h3>
                                <p><small>Please wait file is downloading.</small></p>
                            </div>
                            : <div className="db-button" onClick={this.downloadSampleFile}>
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Download Sample File
                                </h3>
                                <p><small>Download CSV file which contains the sample format.</small></p>
                            </div>}
                        </div>
                        <div onClick={this.openFileSelector} className="column is-paddingless">
                            {uploading 
                            ? <div className="db-button">
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Uploading...
                                </h3>
                                <p><small>Please wait, file is uploading.</small></p>
                            </div>
                            : <div className="db-button">
                                <h3 className="has-text-weight-bold mg-bottom-5">
                                    Upload Data File
                                </h3>
                                <p><small>Upload maximum <b>2000 users</b> at a time in CSV.</small></p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                {!uploading ? <input type="file" id='uploadUsers' accept=".csv" onChange={this.uploadCSV} hidden/> : null}
            </div>
        )
    }
}
