import React from "react";
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo";
import DatabaseUploadMTMSecure from '../components/databaseupload/DatabaseUploadMTMSecure';
import BrowserBanner from "../components/reusable/BrowserBanner";

const DatabaseUploadSecure = () => (
  <>
  <BrowserBanner />
  <Layout mode={true} sticky_footer={false}>
    <SEOComponent title="Amber by inFeedo | Database Upload" />
    <DatabaseUploadMTMSecure/>
  </Layout>
  </>
)

export default DatabaseUploadSecure;